import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill'
import BtnBase from '@components/common/btn';
import { mixin, breakpoint } from '@src/settings';

export const Cont = styled.div`
	background-color: #000;
`
export const Row = styled.div`
	display: flex;
	${ mixin.baseWidthSet }
	background-color: #FFF;
	& > * + *{
		margin-left: 2px;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		width: 100%;
	`}}
`
export const Frame = styled(Link)`
	flex: 50% 1 1;
	position: relative;
	padding: 7rem 5rem;
	&:after{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		background-color: #000;
		mix-blend-mode: multiply;
		opacity: 0.8;
		transition: opacity 1s linear;
	}
	&:hover{
		&:after{
			transition: opacity 1s linear;
			opacity: 0.3;
		}
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		flex: 50% 0 0;
		padding: 3rem 0 0;
		&:after{
			opacity: 0.3;
		}
	`}}
`

export const Bg = styled(Img)`
	position: absolute !important;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
`

export const Txts = styled.div`
	position: relative;
	z-index: 2;
	& > h2{
		line-height: 2rem;
		margin-bottom: 0.8rem;
		& > svg {
			fill: #FFF;
			height: 1.8rem;
		}
	}
	p {
		font-size: 1.4rem;
		line-height: 1.3;
		color: #FFF;
		margin-bottom: 2.5rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		width: 100%;
		& > .btn{
			width: 100%;
			min-width: inherit;
		}
		& > h2{
			margin-bottom: 0.5rem;
			padding-left: 2rem;
			& > svg{
				height: 1.5rem;
			}
		}
		& > p{
			font-size: 1.3rem;
			padding-left: 2rem;
		}
	`}}
`
export const Btn = styled(BtnBase)`
		max-width: 23rem;
		margin-top: 3rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		max-width: inherit;
		border-left: none;
		border-right: none;
		border-bottom: none;
	`}
`
