import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery } from 'gatsby';
import { Frame, Bg, Txts, Btn } from './styles';
import { Strength, Services, Facility } from './svgs.tsx';


const ItemBase = ({ name, data, lang }) => {

	const itemInfo = {
		strength: {
			title: <Strength />,
			subTitle: "私たちの強み",
			imgName: "strength",
			to: "/strength",
		},
		services: {
			title: <Services />,
			subTitle: "事業紹介",
			imgName: "services",
			to: "/services",
		},
		facility: {
			title: <Facility />,
			subTitle: "設備一覧",
			imgName: "facility",
			to: "/facility",
		}
	}

	return (
		<Frame
			to={ lang == "ja" ? itemInfo[name].to : "/en" + itemInfo[name].to }
		>
			<Bg fluid={ data[itemInfo[name]['imgName']].childImageSharp.fluid } fade={ true } />
			<Txts className="item__content">
				<h2>{ itemInfo[name].title }</h2>
				{ lang =="ja" && (
					<p>{ itemInfo[name].subTitle }</p>
				)}
				<Btn
					className="btn"
					isSkelton={ true }
				/>
			</Txts>
		</Frame>
	)
}

const Item = props => (
	<StaticQuery
		query={ graphql`
			query contentsIntro{

				strength: file( relativePath: { eq: "common/sub-block__strength.jpg" }){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp 
						}
					}
				},
				services: file( relativePath: { eq: "common/subblock_service.jpg" }){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp 
						}
					}
				},
				facility: file( relativePath: { eq: "common/subblock__facility.jpg" }){
					childImageSharp {
						fluid(quality: 90, maxWidth: 950){
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}

			}	
		`}
		render={data => (
			<ItemBase data={ data } { ...props } />	
		)}
	/>
)

const mapStateToProps = (state,props) => ({
	lang: state.langReducer.language,
})
export default connect(mapStateToProps)(Item);
