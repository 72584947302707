import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";

import { Cont, Row } from './styles';
import Item from './item';

type Props = {
	className?: string,
	firstItem: string,
	secondItem: string,
}

const ContentsIntro: React.FC<Props> = (props) => (
	<Cont className={ props.className }>
		<Row>
			<Item name={ props.firstItem } />
			<Item name={ props.secondItem } />
		</Row>
	</Cont>
);

export default ContentsIntro;

