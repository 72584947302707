import React from 'react';

export const Facility = props => (
	<svg viewBox="0 0 168 22" style={ props.style }>
		<g>
			<path d="M1.6.9h15.5v4h-11v4.4h9.7v4H6.1v7.9H1.6V.9z"/>
			<path d="M26.8.7H31l8.7 20.4H35l-1.9-4.6h-8.6l-1.9 4.6H18L26.8.7zm4.7 12l-2.7-6.6-2.7 6.6h5.4z"/>
			<path d="M52.4 21.5c-1.5 0-2.9-.3-4.1-.8-1.3-.5-2.4-1.3-3.3-2.2-.9-.9-1.7-2-2.2-3.3s-.8-2.6-.8-4.1V11c0-1.4.3-2.8.8-4.1.5-1.3 1.2-2.4 2.2-3.3.9-1 2-1.7 3.3-2.3C49.6.7 51 .5 52.6.5c.9 0 1.8.1 2.6.2.8.2 1.5.4 2.1.6.6.3 1.2.6 1.8 1 .5.4 1 .8 1.5 1.3l-2.8 3.3c-.8-.7-1.6-1.3-2.4-1.7s-1.7-.6-2.8-.6c-.9 0-1.6.2-2.4.5-.7.3-1.3.8-1.9 1.4s-.9 1.3-1.2 2-.4 1.6-.4 2.5c0 .9.1 1.7.4 2.5s.7 1.4 1.2 2c.5.6 1.1 1 1.9 1.4.7.3 1.5.5 2.4.5 1.2 0 2.1-.2 2.9-.6.8-.4 1.6-1 2.4-1.7l2.8 2.9c-.5.6-1.1 1.1-1.6 1.5-.6.4-1.2.8-1.8 1.1-.7.3-1.4.6-2.2.7-.9.1-1.8.2-2.7.2zM65.5.9H70v20.3h-4.5V.9zM76.1.9h4.5v16.3h10.1v4H76.1V.9zM95.4.9h4.5v20.3h-4.5V.9z"/>
			<path d="M110.7 5h-6.2V.9h16.8V5h-6.2v16.2h-4.5V5z"/>
			<path d="M131.5 13.1L123.7.8h5.2l4.8 8.1 4.9-8.1h5.1L135.9 13v8.1h-4.5v-8z"/>
		</g>
	</svg>
)

export const Services = props => (
	<svg viewBox="0 0 168 22" style={ props.style }>
		<g>
			<path d="M8.9 21.4c-1.5 0-3.1-.3-4.6-.8-1.5-.5-2.8-1.3-4.1-2.5L2.9 15c.9.8 1.9 1.4 2.9 1.8 1 .4 2 .7 3.2.7.9 0 1.6-.2 2.2-.5.5-.3.8-.8.8-1.4v-.1c0-.3-.1-.5-.2-.8-.1-.2-.3-.4-.6-.6s-.7-.4-1.2-.6c-.5-.2-1.2-.4-2.1-.6-1-.3-2-.5-2.8-.8-.8-.3-1.5-.7-2.1-1.1-.6-.5-1-1-1.4-1.7S1 7.7 1 6.7c0-.9.2-1.8.5-2.5s.9-1.4 1.5-2c.6-.5 1.4-1 2.2-1.2C6.1.7 7 .6 8.1.6c1.5 0 2.9.2 4.1.7s2.4 1.1 3.4 1.9l-2.3 3.4c-.9-.6-1.8-1.1-2.7-1.5S8.9 4.5 8 4.5s-1.5.2-1.9.5c-.4.4-.7.8-.7 1.3v.1c0 .3.1.6.2.9.2.1.4.3.7.5.3.2.8.4 1.4.6s1.3.4 2.1.6c1 .3 1.9.6 2.7.9s1.5.7 2 1.2c.6.5 1 1 1.2 1.7.3.6.4 1.4.4 2.3v.1c0 1-.2 1.9-.5 2.7-.4.8-.9 1.4-1.5 2-.7.5-1.4.9-2.3 1.2-.8.2-1.8.3-2.9.3zM21.2.9h15.3v4H25.7V9h9.6v4h-9.6v4.3h11v4H21.2V.9z"/>
			<path d="M41.7.9H51c2.6 0 4.5.7 5.9 2.1 1.2 1.2 1.7 2.7 1.7 4.6v.1c0 1.6-.4 3-1.2 4-.8 1-1.9 1.8-3.1 2.3l5 7.2h-5.2l-4.4-6.5h-3.6v6.5h-4.5V.9zm9 9.8c1.1 0 1.9-.3 2.5-.8.6-.5.9-1.2.9-2.1 0-1-.3-1.7-.9-2.2s-1.5-.7-2.6-.7h-4.4v5.8h4.5z"/>
			<path d="M61.6.9h4.9l5.3 14.3L77.1.9H82l-8.2 20.4h-3.9L61.6.9zM86.4.9h4.5v20.3h-4.5V.9zM106.5 21.5c-1.5 0-2.9-.3-4.1-.8s-2.4-1.3-3.3-2.2c-.9-.9-1.7-2-2.2-3.3-.5-1.3-.8-2.6-.8-4.1V11c0-1.4.3-2.8.8-4.1.5-1.3 1.2-2.4 2.2-3.3.9-1 2-1.7 3.3-2.3 1.3-.6 2.7-.8 4.3-.8.9 0 1.8.1 2.6.2.8.2 1.5.4 2.1.6.6.3 1.2.6 1.8 1s1 .8 1.5 1.3l-2.8 3.3c-.8-.7-1.6-1.3-2.4-1.7-.8-.4-1.7-.6-2.8-.6-.8 0-1.6.2-2.4.5-.7.3-1.3.8-1.9 1.4-.5.6-.9 1.3-1.2 2s-.4 1.6-.4 2.5c0 .9.1 1.7.4 2.5s.7 1.4 1.2 2c.5.6 1.1 1 1.9 1.4.7.3 1.5.5 2.4.5 1.2 0 2.1-.2 2.9-.6.8-.4 1.6-1 2.4-1.7l2.8 2.9c-.5.6-1.1 1.1-1.6 1.5-.6.4-1.2.8-1.8 1.1-.7.3-1.4.6-2.2.7s-1.7.2-2.7.2zM119.5.9h15.3v4h-10.9V9h9.6v4h-9.6v4.3h11v4h-15.4V.9z"/>
			<path d="M147.2 21.4c-1.5 0-3.1-.3-4.6-.8-1.5-.5-2.8-1.3-4.1-2.5l2.6-3.2c.9.8 1.9 1.4 2.9 1.8 1 .4 2 .7 3.2.7.9 0 1.6-.2 2.2-.5.5-.3.8-.8.8-1.4v-.1c0-.3-.1-.5-.2-.8-.1-.2-.3-.4-.6-.6-.3-.2-.7-.4-1.2-.6s-1.2-.4-2.1-.6c-1-.3-2-.5-2.8-.8-.8-.3-1.5-.7-2.1-1.1-.6-.5-1-1-1.4-1.7-.3-.7-.5-1.5-.5-2.5 0-.9.2-1.8.5-2.5s.8-1.4 1.5-1.9c.6-.5 1.4-1 2.2-1.2s1.8-.4 2.9-.4c1.5 0 2.9.2 4.1.7s2.4 1.1 3.4 1.9l-2.3 3.4c-.9-.6-1.8-1.1-2.7-1.5s-1.7-.5-2.6-.5-1.5.2-2 .5c-.4.3-.7.8-.7 1.3v.1c0 .3.1.6.2.9.1.2.4.5.7.7s.8.4 1.4.6c.6.2 1.3.4 2.2.6 1 .3 1.9.6 2.7.9s1.5.7 2 1.2c.6.5 1 1 1.2 1.7.3.6.4 1.4.4 2.3v.1c0 1-.2 1.9-.5 2.7-.4.8-.9 1.4-1.5 2-.7.5-1.4.9-2.3 1.2-.7-.2-1.7-.1-2.9-.1z"/>
		</g>
	</svg>
)

export const Strength = props => (
<svg viewBox="0 0 168 22" style={ props.style }>
	<g>
		<path d="M8.9,21.4c-1.5,0-3.1-0.3-4.6-0.8c-1.5-0.5-2.8-1.3-4.1-2.5L2.9,15c0.9,0.8,1.9,1.4,2.9,1.8
			c1,0.4,2,0.7,3.2,0.7c0.9,0,1.6-0.2,2.2-0.5c0.5-0.3,0.8-0.8,0.8-1.4v-0.1c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.6
			s-0.7-0.4-1.2-0.6c-0.5-0.2-1.2-0.4-2.1-0.6c-1-0.3-2-0.5-2.8-0.8c-0.8-0.3-1.5-0.7-2.1-1.1c-0.6-0.5-1-1-1.4-1.7S1,7.7,1,6.7V6.7
			c0-0.9,0.2-1.8,0.5-2.5S2.4,2.8,3,2.2c0.6-0.5,1.4-1,2.2-1.2c0.9-0.3,1.8-0.4,2.9-0.4c1.5,0,2.9,0.2,4.1,0.7s2.4,1.1,3.4,1.9
			l-2.3,3.4c-0.9-0.6-1.8-1.1-2.7-1.5S8.9,4.5,8,4.5S6.5,4.7,6.1,5C5.7,5.4,5.4,5.8,5.4,6.3v0.1c0,0.3,0.1,0.6,0.2,0.9
			C5.8,7.4,6,7.6,6.3,7.8C6.6,8,7.1,8.2,7.7,8.4S9,8.8,9.8,9c1,0.3,1.9,0.6,2.7,0.9s1.5,0.7,2,1.2c0.6,0.5,1,1,1.2,1.7
			c0.3,0.6,0.4,1.4,0.4,2.3v0.1c0,1-0.2,1.9-0.5,2.7c-0.4,0.8-0.9,1.4-1.5,2c-0.7,0.5-1.4,0.9-2.3,1.2C11,21.3,10,21.4,8.9,21.4z"/>
		<path d="M25.5,5h-6.2V0.9h16.8V5H30v16.2h-4.5V5z"/>
		<path d="M40.7,0.9H50c2.6,0,4.5,0.7,5.9,2.1c1.2,1.2,1.7,2.7,1.7,4.6v0.1c0,1.6-0.4,3-1.2,4c-0.8,1-1.9,1.8-3.1,2.3
			l5,7.2H53l-4.4-6.5h-0.1h-3.5v6.5h-4.5V0.9z M49.7,10.7c1.1,0,1.9-0.3,2.5-0.8c0.6-0.5,0.9-1.2,0.9-2.1V7.8c0-1-0.3-1.7-0.9-2.2
			s-1.5-0.7-2.6-0.7h-4.4v5.8H49.7z"/>
		<path d="M62.7,0.9H78v4H67.1v4.1h9.6v4h-9.6v4.3h11v4H62.7V0.9z"/>
		<path d="M83.2,0.9h4.1l9.5,12.5V0.9h4.4v20.3h-3.8L87.6,8.2v12.9h-4.4V0.9z"/>
		<path d="M117,21.5c-1.6,0-3-0.3-4.3-0.8c-1.3-0.5-2.4-1.2-3.4-2.2c-0.9-0.9-1.7-2-2.2-3.3c-0.5-1.3-0.8-2.7-0.8-4.2V11
			c0-1.4,0.3-2.8,0.8-4.1c0.5-1.3,1.3-2.4,2.2-3.3c0.9-1,2.1-1.7,3.4-2.3s2.7-0.8,4.3-0.8c0.9,0,1.7,0.1,2.5,0.2
			c0.7,0.1,1.4,0.3,2.1,0.5c0.6,0.2,1.2,0.5,1.8,0.9c0.6,0.3,1.1,0.7,1.6,1.2l-2.8,3.4c-0.4-0.3-0.8-0.6-1.2-0.9
			c-0.4-0.3-0.8-0.5-1.2-0.6c-0.4-0.2-0.9-0.3-1.3-0.4s-1-0.1-1.6-0.1c-0.8,0-1.6,0.2-2.3,0.5c-0.7,0.3-1.3,0.8-1.9,1.4
			c-0.5,0.6-0.9,1.2-1.2,2S111,10.1,111,11V11c0,0.9,0.1,1.8,0.4,2.6c0.3,0.8,0.7,1.5,1.3,2s1.2,1,1.9,1.4c0.7,0.3,1.6,0.5,2.5,0.5
			c1.7,0,3.1-0.4,4.2-1.2v-2.9h-4.5V9.5h8.8v8.8c-1,0.9-2.3,1.6-3.7,2.3S118.8,21.5,117,21.5z"/>
		<path d="M135.4,5h-6.2V0.9h16.8V5h-6.2v16.2h-4.5V5z"/>
		<path d="M150.6,0.9h4.5v8h8.2v-8h4.5v20.3h-4.5V13H155v8.1h-4.5V0.9z"/>
	</g>
</svg>
) 
