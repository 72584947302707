import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { mixin, size, breakpoint } from '@src/settings';

export const Container = styled.div`
	margin-top: 7.5rem;
	margin-bottom: 7.5rem;
	${ mixin.baseWidthSet }
	& > .column + .column{
		margin-top: 1rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-top: ${ size.sectionGap_sp }px;
		& > .column + .column{
			margin-top: 3rem;
		}
	`}
`
