import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import ImgBase from 'gatsby-image/withIEPolyfill';
import BtnBase from '@components/common/btn';
import { color,breakpoint } from '@src/settings';

export const Row = styled.div`
  display:flex;
  justify-content:center;
  margin: 0 auto;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: block;
	`}
`
export const Img = styled(ImgBase)`
	object-fit: cover;
	flex: 30rem 0 0;
	${ media.lessThan(`${ breakpoint.tb }px`)`
		flex-basis: 50%;
	`}
`
export const Txt = styled.div`
	flex: auto 1 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #FFF;
	padding: 4.5rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding: 2.5rem;
	`}
`
export const Title = styled.h3`
	font-size: 1.5rem;
	font-weight: bold;
	text-align: left;
`
export const En = styled.p`
	color: ${color.txtLight};
	display: block;
	font-size: 1.2rem;
	margin-top: 0.5rem;
	text-align: left;
`
export const Cap = styled.p`
	font-size: 1.4rem;
	line-height: 1.7;
	margin-top: 1.3rem;
	text-align: left;
`
export const Btn = styled(BtnBase)`
	width: 22rem;
	margin-top: 2.6rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		width: 100%;
		margin-top: 2rem;
	`}
`
