import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { mixin } from '@src/settings';
import { createBreakpoint } from 'react-use';


import Hero from '@components/en/common/hero';
import PageIntro from '@components/common/page-intro';
import ContentsIntro from '@components/common/contents-intro';
import ContactIntro from '@components/en/common/contact-intro';

import Service_hero__mds from '@img/svg/service/hero__mds.svg';
import Service_guide__mds__strength from '@img/svg/common/service-guide__mds--strength.svg';
import Service_guide__mds__facility from '@img/svg/common/service-guide__mds--facility.svg';

import Layout from '@components/common/layout';
import Column from '@components/services/column';
import { Container } from '@components/services/styles';
import { color, breakpoint } from '@src/settings';

const useBreakpoint = createBreakpoint(breakpoint);

const Services_page = ({ data }) => {

	const bp = useBreakpoint();	

	return (
		<Layout baseBgColor={ color.paleGray }>

			<Hero
				img={ data["heroImg"]}
				title={<Service_hero__mds />}
			/>
			
			<PageIntro
				title="As a Primary Supplier to major Automobile Manufacturers"
				subtitle="we will continue to lead in providing expertise and services precisely needed in the field."
			 />

			<Container >

				<Column
					className="column"
					img={ bp == "pc" ?  data["Service_1"] : data["Service_1_sp"] }
					title="The Effective IKKANSEISAN System"
					cap="From the Development to Production of parts, all process are accomplished within the same factory. We are the only company in Malaysia that specialized in IKKANSEISAN System, enable us to achieve Good Quality, Short Lead Time, Competitive Cost."
					to="/en/flow"
				/>

				<Column
					className="column"
					img={ data["Service_2"] }
					img={ bp == "pc" ?  data["Service_2"] : data["Service_2_sp"] }
					title="Adoption and Innovation of New Technology"
					cap="We manufacture automobile parts of various sizes and shapes, including Outer Panel parts, Shell Body parts, Under Body parts, Assembly parts, and Precision Machined parts. We also provide customized design and manufacturing of equipments or parts according to customer's request."
					to="/en/products"
				/>

				<Column
					className="column"
					img={ bp == "pc" ?  data["Service_3"] : data["Service_3_sp"] }
					title="Our L.S.F. Missions"
					cap="To preserve the harmony between people, environment, and society, we aim to create win-win relationship with all of our stakeholders by fulfilling our corporate missions and through CSR activities."
					to="/en/quality"
				/>
			</Container>

			<ContentsIntro
				firstItem="strength"
				secondItem="facility"
			/>

		</Layout> 
	)
}

export default Services_page


export const query = graphql`
	query serviceImgs_en{

		heroImg: file( relativePath: { eq: "services/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_1: file( relativePath: { eq: "services/service_1.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_2: file( relativePath: { eq: "services/service_2.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_3: file( relativePath: { eq: "services/service_3.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_1_sp: file( relativePath: { eq: "services/service-flow--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_2_sp: file( relativePath: { eq: "services/service-products--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_3_sp: file( relativePath: { eq: "services/service-quality--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},


	},
`;
