import React from 'react';
import { Row, Img, Txt, Title, En, Cap, Btn } from './styles';

type Props = {
	title: string,
	en: string | React.RreactElement,
	cap: string,
	to: string,
	className?: string,
}

const Column = (props) => (
	<Row className={ props.className }>
		<Img fluid={ props.img.childImageSharp.fluid }/>
		<Txt>
			<Title>{ props.title }</Title>
			<En>{ props.en }</En>
			<Cap>{ props.cap}</Cap>
			<Btn to={ props.to }/>
		</Txt>
	</Row>
)

export default Column
